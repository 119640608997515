// @ts-check

import Swal from 'sweetalert2';

const SwalConfirmation = Swal.mixin({
  showCloseButton: true,
  showCancelButton: true,
  focusConfirm: false,
  confirmButtonText: 'Ya',
  cancelButtonText: 'Tidak',
});

const SwalLoading = Swal.mixin({
  allowOutsideClick: () => !Swal.isLoading(),
  title: 'Sedang memproses...',
  html: 'Mohon menunggu beberapa saat.',
  didOpen: () => {
    Swal.showLoading(null);
  },
});

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export { SwalConfirmation, SwalLoading, Toast };
